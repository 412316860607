import {
  Component,
  OnInit,
  HostListener,
  Inject,
  PLATFORM_ID,
  isDevMode,
} from '@angular/core';
import { AuthService } from './services/auth/auth.service';
import { LoaderService } from './services/loader/loader.service';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { UtilitiesService } from './services/utilities/utilities.service';
import { DashService } from './services/dash/dash.service';
import { TranslateService } from '@ngx-translate/core';
// import { PixelService } from 'ngx-multi-pixel';
import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import {
  CommonModule,
  isPlatformBrowser,
  isPlatformServer,
} from '@angular/common';
import { delay, filter } from 'rxjs';
import { allCountries } from './services/data/countries';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    FooterComponent,
    NavbarComponent,
    MatProgressSpinner,
  ],
})
export class AppComponent implements OnInit {
  constructor(
    public auth: AuthService,
    public loaderservice: LoaderService,
    public router: Router,
    public utilities: UtilitiesService,
    //Being used in the template
    // private pixel: PixelService,
    public dash: DashService,

    public translate: TranslateService,
    // translateCacheService: TranslateCacheService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    if (isPlatformServer(platformId)) {
      console.log('Server');
    } else {
      console.log('Web');
    }
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        const currentRoute = this.router.url;
        this.iswebview = currentRoute.startsWith('/app');
      });
    this.closeNavbar = false;
    // translateCacheService.init();
    translate.addLangs(['en', 'fr', 'cz']);
    translate.setDefaultLang('fr');
    if (isPlatformBrowser(platformId)) {
      if (!window.location.protocol.startsWith('https') && !isDevMode()) {
        // const newUrl = `https://${window.location.hostname}${window.location.pathname}${window.location.search}`;
        // window.location.href = newUrl;
      }
      // const browserLang = translateCacheService.getCachedLanguage();
      translate.use('fr');
      // console.log(browserLang);
    }
    // pixel.initialize();
    // pixel.track('PageView');

    this.router.events.subscribe(() => {
      this.closeNavbar = !this.closeNavbar;
    });
    //   .subscribe();
  }
  isloading$ = this.loaderservice.isloading$.pipe(delay(0));
  iswebview = false;
  title = 'Phonix Health';
  langs = ['en', 'fr'];
  public webviewStyle = {
    'background-color': 'var(--phonix-blue-white)',
    height: '100vh',
  };
  closeNavbar = false;
  @HostListener('document:click', ['$event'])
  public onClick(targetElement) {
    if (isPlatformBrowser(this.platformId)) {
      if (
        targetElement.target.className &&
        typeof targetElement.target.className === 'string'
      ) {
        const clickedInsideNavbar =
          targetElement.target.className.startsWith('sidebar') ||
          targetElement.target.className.startsWith(
            'mat-mdc-button-touch-target'
          );
        if (!clickedInsideNavbar) {
          this.closeNavbar = !this.closeNavbar;
        }
      }
    }
  }
  public nowebviewStyle = {};
  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Extract 'lang' from the current URL
        const urlSegments = this.router.url.split('/');
        const user_country = urlSegments[1].slice(3) || 'FR';
        const lang = urlSegments[1].slice(0, 2) || 'fr'; // Default to 'en'
        if (lang in allCountries) {
          const found_country = allCountries[lang].find(
            (country) => country.code === user_country
          );
          this.utilities.user_country.next(found_country);
        }
        if (this.translate.langs.includes(lang)) {
          this.utilities.setLang(lang);
          this.translate.use(lang);
        }
      }
    });
    this.utilities.updateCanonicalUrl();
  }
}
