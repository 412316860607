import {
  APP_INITIALIZER,
  ApplicationConfig,
  importProvidersFrom,
} from '@angular/core';
import {
  InMemoryScrollingOptions,
  provideRouter,
  withInMemoryScrolling,
} from '@angular/router';

import { routes } from './app.routes';
import {
  HttpClient,
  HttpClientModule,
  provideHttpClient,
  withFetch,
  withInterceptors,
} from '@angular/common/http';
import { httpiInterceptor } from './services/interceptor/httpi.interceptor';
import { provideClientHydration } from '@angular/platform-browser';
import { AuthService } from './services/auth/auth.service';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { provideToastr } from 'ngx-toastr';
import {
  BrowserAnimationsModule,
  provideAnimations,
} from '@angular/platform-browser/animations';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';
import { UtilitiesService } from './services/utilities/utilities.service';
import { provideHotToastConfig } from '@ngneat/hot-toast';

export function checkUser(authService: AuthService) {
  return () => authService.checkAuthentication(); // Assuming this is an async operation
}

export function checkLang(UtilitiesService: UtilitiesService) {
  return () => UtilitiesService.checkLang(); // Assuming this is an async operation
}

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}
const scrollConfig: InMemoryScrollingOptions = {
  scrollPositionRestoration: 'top',
  anchorScrolling: 'enabled',
};
export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, withInMemoryScrolling(scrollConfig)),

    provideCharts(withDefaultRegisterables()),

    provideAnimations(), // required animations providers
    provideToastr(),
    importProvidersFrom(BrowserAnimationsModule),
    importProvidersFrom(HttpClientModule),
    provideHotToastConfig(), // @ngneat/hot-toast providers

    importProvidersFrom(
      TranslateModule.forRoot({
        defaultLanguage: 'fr',
        useDefaultLang: true,
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        },
      })
    ),
    provideHttpClient(withInterceptors([httpiInterceptor]), withFetch()),
    provideClientHydration(),

    {
      provide: APP_INITIALIZER,
      useFactory: checkUser,
      deps: [AuthService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: checkLang,
      deps: [UtilitiesService],
      multi: true,
    },
  ],
};
